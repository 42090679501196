import React from 'react';
import classes from './styles.module.scss';
import Tooltip from '../tooltip';

const ToggleSwitch: React.FC<{
  checked: boolean;
  onToggleChange: () => void;
  tooltipText?: string;
}> = ({ checked, onToggleChange, tooltipText }) => {
  return (
    <div className={classes['container']}>
      <Tooltip content={tooltipText}>
        <div
          onClick={onToggleChange}
          className={`${classes['slider']} ${checked ? classes['active'] : ''}`}
        >
          <span className={classes['slider-thumb']}></span>
        </div>
      </Tooltip>
    </div>
  );
};

export default ToggleSwitch;

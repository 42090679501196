import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Diagram from 'src/components/carddiagram';
import {
  DiagramCompanyType,
  DiagramDivisionType,
  DiagramEmissionType,
  ISource,
} from 'src/consts/types';
import { getShortName } from 'src/utils/helpers';
import { dashboardDiagram, getEmissionType } from 'src/requests/dashboard';
import DashboardLineChart from './components/DashboardLineChart';
import { getAllSources } from 'src/requests/source';

const Dashboard: React.FC = () => {
  const [list, setList] = useState<{
    companyList: DiagramCompanyType[];
    divisionList: DiagramDivisionType[];
    emissionList: DiagramEmissionType[];
  }>({
    companyList: [],
    divisionList: [],
    emissionList: [],
  });
  const currentDate = new Date();
  const currentMonth =
    currentDate.toLocaleString('default', { month: 'long' }).split('')[0].toUpperCase() +
    currentDate.toLocaleString('default', { month: 'long' }).slice(1);

  const [companyDate, setCompanyDate] = useState<{ name: string; label: string; id: number }[]>([
    {
      name: currentMonth,
      label: currentMonth,
      id: currentDate.getMonth() + 1,
    },
  ]);
  const [divisionDate, setDivisionDate] = useState<{ label: string; id: number; name: string }[]>([
    { name: currentMonth, label: currentMonth, id: currentDate.getMonth() + 1 },
  ]);
  const [emissionDate, setEmissionDate] = useState<{ label: string; id: number; name: string }[]>([
    { name: currentMonth, label: currentMonth, id: currentDate.getMonth() + 1 },
  ]);
  const currentYear = currentDate.getFullYear().toString();

  const [companyYear, setCompanyYear] = useState<string>(currentYear);
  const [divisionYear, setDivisionYear] = useState<string>(currentYear);
  const [emissionYear, setEmissionYear] = useState<string>(currentYear);
  const [selectedSources, setSelectedSources] = useState<ISource[]>([]);
  const [sourcesList, setSourcesList] = useState<ISource[]>([]);

  const selectedList = {
    companyDate,
    divisionDate,
    emissionDate,
    setCompanyDate,
    setDivisionDate,
    setEmissionDate,
  };

  useEffect(() => {
    dashboardDiagram('company', `${companyYear}-0${companyDate[0].id}`).then((res) => {
      const companyList: DiagramCompanyType[] = res.map((item: DiagramCompanyType) => {
        return {
          ...item,
          id: item.id,
          name: getShortName(item.name),
          label: item.name,
          listType: 'company',
          columnName: 'Предприятия',
        };
      });
      setList((prev) => ({
        ...prev,
        companyList,
      }));
    });
  }, [companyDate, companyYear]);

  useEffect(() => {
    dashboardDiagram('division', `${divisionYear}-0${divisionDate[0].id}`).then((data) => {
      const divisionList = data.map((item: DiagramDivisionType) => {
        return {
          ...item,
          id: item.id,
          name: item.name,
          label: item.name,
          listType: 'division',
          columnName: 'Дивизионы',
        };
      });

      setList((prev) => ({
        ...prev,
        divisionList,
      }));
    });
  }, [divisionDate, divisionYear]);

  useEffect(() => {
    const sources = selectedSources.map((item) => item.id);
    getEmissionType(`${emissionYear}-0${emissionDate[0].id}`, sources && sources).then((data) => {
      const emissionList = data.map((item: DiagramDivisionType, index: number) => {
        return {
          ...item,
          id: index + 1,
          name: item.name,
          label: item.name,
          listType: 'emission',
          columnName: 'Загрязняющие вещества',
        };
      });

      setList((prev) => ({
        ...prev,
        emissionList,
      }));
    });
  }, [emissionDate, emissionYear, selectedSources]);

  useEffect(() => {
    getAllSources().then((res) => {
      setSourcesList(res.list.map((item) => ({ ...item, label: item.name, name: item.name })));
    });
  }, []);

  useEffect(() => {
    selectedSources.length === 0 && setSelectedSources(sourcesList);
  }, [sourcesList]);

  return (
    <>
      <div className={styles['cards']}>
        <Diagram
          name={'Предприятия'}
          listType={'company'}
          list={list.companyList}
          selectedList={selectedList.companyDate}
          setSelectedList={selectedList.setCompanyDate}
          year={companyYear}
          setYear={setCompanyYear}
          itemNameWidth={'90px'}
        />
        <Diagram
          name={'Дивизионы'}
          listType={'division'}
          list={list.divisionList}
          selectedList={selectedList.divisionDate}
          setSelectedList={selectedList.setDivisionDate}
          year={divisionYear}
          setYear={setDivisionYear}
          itemNameWidth={'120px'}
        />
        <Diagram
          name={'Загрязняющие вещества'}
          listType={'emission'}
          list={list.emissionList}
          selectedList={selectedList.emissionDate}
          setSelectedList={selectedList.setEmissionDate}
          year={emissionYear}
          setYear={setEmissionYear}
          sourceList={sourcesList}
          selectedSources={selectedSources}
          setSelectedSources={setSelectedSources}
          itemNameWidth={'60px'}
        />
      </div>
      <DashboardLineChart />
    </>
  );
};

export default Dashboard;

import React from 'react';
import classes from './styles.module.scss';
import classNames from 'classnames';

interface IProps {
  children?: React.ReactElement;
  className?: string;
  styles?: React.CSSProperties;
}

const Card: React.FC<IProps> = ({ children, className, styles }) => {
  return (
    <div style={styles} className={classNames(classes['card'], classes[className || ''])}>
      {children}
    </div>
  );
};

export default Card;

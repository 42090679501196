import React, { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ArrowDown, HomeSmallIcon } from 'src/assets/icons';
import { useUserContext } from 'src/context/useUserContext';
import classes from './styles.module.scss';
import { removeTokens } from 'src/utils/token';
import classNames from 'classnames';

interface IModule {
  id: number;
  title: string;
  link: string;
  disabled: boolean;
  subModules?: IModule[];
}

const moduleList: IModule[] = [
  {
    id: 1,
    title: 'Водные ресурсы',
    link: '',
    disabled: true,
  },
  {
    id: 2,
    title: 'Атмосферный воздух',
    link: '',
    disabled: true,
  },
  {
    id: 3,
    title: 'АСМ',
    link: '',
    disabled: false,
    subModules: [
      { id: 4, title: 'Выбросы', link: '/main', disabled: false },
      { id: 5, title: 'Сбросы', link: '', disabled: true },
    ],
  },
  {
    id: 6,
    title: 'ОПП',
    link: '',
    disabled: true,
  },
  {
    id: 7,
    title: 'Разрешение на эмиссии',
    link: '',
    disabled: true,
  },
  {
    id: 8,
    title: 'Парниковые газы',
    link: '',
    disabled: true,
  },
  {
    id: 9,
    title: 'Управление проверками',
    link: '',
    disabled: true,
  },
  {
    id: 10,
    title: 'Управление мероприятиями в области ООС',
    link: '',
    disabled: true,
  },
  {
    id: 11,
    title: 'Управление проектами',
    link: '',
    disabled: true,
  },
];

const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUserContext();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [isDropdownModule, setIsDropdownModule] = useState<boolean>(false);
  const [showSubModules, setShowSubModules] = useState<number>(0);

  const handleLogOut = () => {
    removeTokens();
    navigate('/login');
  };

  const handleModuleClick = (module: IModule) => {
    if (module.id === showSubModules) {
      setShowSubModules(0);
    } else if (module.subModules) {
      setShowSubModules(module.id);
    } else {
      navigate(module.link);
    }
  };

  if (!user) return null;

  return (
    <div className={classes['layout']}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          background: '#fff',
          padding: '0 2rem',
          alignItems: 'center',
        }}
      >
        <div>
          <div className={classes['layout__header']}>
            <div className={classes['layout__header__icons']}>
              <div
                className={classes['profile']}
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <span>{user.firstName[0] + user.lastName[0]}</span>
              </div>
            </div>
          </div>
          {isDropdownOpen && (
            <div
              className={classes['dropdown']}
              style={{
                flexDirection: 'column',
                position: 'absolute',
              }}
            >
              <p>{user.firstName + ' ' + user.lastName}</p>
              <span>{user.position}</span>
              <ul>
                <li onClick={handleLogOut}>
                  <img src={HomeSmallIcon} alt="home" />
                  <p>Выход</p>
                </li>
              </ul>
            </div>
          )}
        </div>
        <div style={{ display: 'flex', gap: 30 }}>
          <div
            onClick={() => navigate('/main')}
            style={{
              padding: '24px',
              borderBottom: location.pathname === '/main' ? '4px solid #6496FB' : 'none',
              cursor: 'pointer',
            }}
          >
            Главная
          </div>
          <div
            onClick={() => navigate('/dashboard')}
            style={{
              padding: '24px',
              borderBottom: location.pathname === '/dashboard' ? '4px solid #6496FB' : 'none',
              cursor: 'pointer',
            }}
          >
            Дэшборд
          </div>
          <div>
            <div
              onClick={() => setIsDropdownModule(!isDropdownModule)}
              style={{
                padding: '24px',
                cursor: 'pointer',
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                gap: '15px',
                userSelect: 'none',
              }}
            >
              <span>АСМ-выбросы</span>
              <span className={classes[`triangle-${isDropdownModule ? 'up' : 'down'}`]}></span>
            </div>
            {isDropdownModule && (
              <div className={classes['dropdown__options']}>
                <div
                  style={{ padding: '16px 32px', display: 'flex', justifyContent: 'space-between' }}
                >
                  <div
                    style={{
                      fontWeight: '700',
                      fontSize: '20px',
                      lineHeight: '24px',
                      color: '#383874',
                    }}
                  >
                    МОДУЛИ
                  </div>
                  <div
                    onClick={() => setIsDropdownModule(false)}
                    style={{ cursor: 'pointer' }}
                    className={classes['cross']}
                  ></div>
                </div>
                {moduleList.map((item: IModule) => (
                  <>
                    <div
                      key={item.id}
                      className={classNames(
                        classes['dropdown__option-wrap'],
                        classes[`dropdown__option-wrap--${item.disabled && 'disabled'}`],
                      )}
                      style={{ opacity: item.disabled ? 0.5 : 1 }}
                      onClick={() => !item.disabled && handleModuleClick(item)}
                    >
                      <p>{item.title}</p>
                      {item.subModules && <span className={classes[`triangle-down`]}></span>}
                    </div>
                    {showSubModules === item.id &&
                      item.subModules?.map((item) => (
                        <div
                          key={item.id}
                          className={classNames(
                            classes['dropdown__option-wrap2'],
                            classes[`dropdown__option-wrap2--${item.disabled && 'disabled'}`],
                          )}
                          style={{ opacity: item.disabled ? 0.5 : 1 }}
                          onClick={() => !item.disabled && handleModuleClick(item)}
                        >
                          <p>{item.title}</p>
                        </div>
                      ))}
                  </>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default Layout;

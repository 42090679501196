import React from 'react';
import classes from './styles.module.scss';
import classNames from 'classnames';

interface IInputProps {
  label?: string;
  placeholder?: string;
  value?: string | number | null;
  disabled?: boolean;
  type?: string;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isValid?: boolean;
  className?: string;
  errorMsg?: string;
  icon?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  variant?: 'withEdit' | 'default';
  min?: number;
  max?: number;
}

const Input: React.FC<IInputProps> = ({
  label,
  placeholder,
  value,
  disabled,
  onChange,
  name,
  isValid,
  className,
  errorMsg,
  icon,
  type = 'text',
  onKeyDown,
  variant = 'default',
  min,
  max,
}) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event);
  };

  return (
    <div className={classNames(classes['input'], className)}>
      {label && <label className={classes['input__label']}>{label}</label>}
      <div className={classNames(classes['input__wrapper'], icon && classes['helper'])}>
        {icon && <img src={icon} alt="icon" className={classes['input__front-icon']} />}
        <input
          name={name}
          type={type}
          className={classNames(
            classes['input__field'],
            isValid !== undefined && !isValid && classes['input__field--invalid'],
          )}
          placeholder={placeholder}
          value={value ?? ''}
          onChange={handleInputChange}
          disabled={variant === 'default' && disabled}
          autoComplete={'nope'}
          onKeyDown={onKeyDown}
          min={min}
          max={max}
          onWheel={(e: any) => e.target.blur()}
        />
      </div>
      {isValid !== undefined && !isValid && (
        <div className={classes['input__tooltip']}>{errorMsg}</div>
      )}
    </div>
  );
};

export default Input;

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import classes from './styles.module.scss';
import { ArrowBlack } from 'src/assets/icons';
import { ICompany, ISource, ISourceInfo } from 'src/consts/types';
import { getEmissionsDashboard } from 'src/requests/source';
import Card from 'src/components/card';
import { ERG } from 'src/assets/images';
import classNames from 'classnames';
import ToggleSwitch from 'src/components/toggle';

type PropsType = {
  selectedSource?: ISource;
  onClose?: () => void;
  selectedCompany?: ICompany;
  sourcesList?: ISource[];
  setSelectedSource: Dispatch<SetStateAction<ISource | undefined>>;
  showDivisions: boolean;
  changeCardInfoType: () => void;
};

const CardInfo: React.FC<PropsType> = ({
  selectedSource,
  setSelectedSource,
  selectedCompany,
  sourcesList,
  showDivisions,
  changeCardInfoType,
}) => {
  const [showSourcesList, setShowSourcesList] = useState<boolean>(false);
  const [sourcesInfoList, setSourcesInfoList] = useState<ISourceInfo[]>([]);

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  const handleChooseSource = (source: ISource) => {
    setSelectedSource(source);
    setShowSourcesList(false);
  };

  const getLevel = (value: number, limit: number): string => {
    const percent = (value * 100) / limit;
    if (percent >= 100) {
      return 'high';
    } else if (percent >= 90 || percent > 100) {
      return 'normal';
    } else {
      return 'low';
    }
  };

  useEffect(() => {
    if (selectedSource)
      getEmissionsDashboard([selectedSource.id]).then((res) => setSourcesInfoList(res.list));
    else setSourcesInfoList([]);
  }, [selectedSource]);

  return (
    <Card
      styles={{
        width: '25%',
      }}
    >
      <>
        <div className={classes['card__header']}>
          <img src={ERG} alt="icon" />
          <p style={{ whiteSpace: 'nowrap' }}>{selectedCompany?.name}</p>
          <div onClick={() => setShowSourcesList(!showSourcesList)}>
            <p>{selectedSource?.name}</p>
            {showSourcesList && (
              <div className={classes['card__sources']}>
                {sourcesList
                  ?.filter((it) => it.id !== selectedSource?.id)
                  .map((item) => (
                    <span key={item.id} onClick={() => handleChooseSource(item)}>
                      {item.name}
                    </span>
                  ))}
              </div>
            )}
            {selectedSource?.name && (
              <img
                src={ArrowBlack}
                alt="arr"
                className={classes[showSourcesList ? 'arrow2' : 'arrow']}
              />
            )}
          </div>
          <ToggleSwitch
            tooltipText={showDivisions ? 'Дивизион' : 'Предприятие'}
            checked={showDivisions}
            onToggleChange={changeCardInfoType}
          />
        </div>
        <div className={classes['line']}></div>
        <div className={classes['card__body']}>
          {sourcesInfoList[0]?.params &&
            Object.entries(sourcesInfoList[0]?.params).map(([key, value]) => (
              <div key={key}>
                <p>{key}</p>
                <div className={classes['card__flex']}>
                  <div>
                    <p>Выбросы</p>
                    <span className={classes['card__body__info2']}>
                      {value.Emissions?.value} г/c
                    </span>
                  </div>
                  <div>
                    {
                      // (value.WasteConcentration.value * 100) / value.WasteConcentration.limit >
                      //   90 && (
                      <>
                        <p>Концентрация</p>
                        <div className={classes['card__body__wrap']}>
                          <span
                            className={classNames(
                              classes['card__body__info2'],
                              classes[
                                `card__body__info--${getLevel(
                                  value.WasteConcentration?.value,
                                  value.WasteConcentration?.limit,
                                )}`
                              ],
                            )}
                          >
                            {value.WasteConcentration?.value?.toFixed(4)} мг/нм³
                          </span>
                        </div>
                      </>
                      // )
                    }
                  </div>
                </div>
              </div>
            ))}
          <p className={classes['card__body__more']} onClick={scrollToBottom}>
            Подробнее
          </p>
        </div>
      </>
    </Card>
  );
};

export default CardInfo;

import { api } from './api';

const baseUrl = '/dashboard';

export type chartType = 'DAY' | 'MONTH' | 'TWENTY_MINUTE' | 'WEEK' | 'YEAR';

export const dashboardDiagram = (type: 'company' | 'division', date: string) => {
  const response = api
    .get(`${baseUrl}/${type}`, {
      params: {
        date,
      },
    })
    .then((res) => res.data);
  return response;
};

export const getEmissionType = (date: string, sourceId?: number[]) => {
  const response = api
    .get(`${baseUrl}/emissionType`, {
      params: {
        date,
        sourceId: sourceId ? sourceId.join(',') : undefined,
      },
    })
    .then((res) => res.data);
  return response;
};

export const getDashboardChart = (
  chart: chartType,
  division: boolean,
  emissionType: string[],
  sourceIds: number[],
) => {
  const response = api.get(`${baseUrl}/chart`, {
    params: {
      chart,
      division,
      emissionType: emissionType.join(','),
      sourceId: sourceIds.join(','),
    },
  });

  return response;
};

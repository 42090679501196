import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { resetPassword } from 'src/requests/auth';

import classes from '../login/styles.module.scss';
import classNames from 'classnames';
import * as Yup from 'yup';
import { LockIcon } from 'src/assets/icons';
import { Input, Button } from 'src/components';

export interface IPasswordForm {
  confirmPassword: string;
  password: string;
}

const resetPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Поле обязательное')
    .min(8, 'Пароль не может быть менее 8 символов')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
      'Минимум 1 символ, 1 заглавная буква, 1 цифра',
    ),
  confirmPassword: Yup.string()
    .required('Поле обязательное')
    .oneOf([Yup.ref('password')], 'Пароли не совпадают'),
});

const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [form, setForm] = useState<IPasswordForm>({
    password: '',
    confirmPassword: '',
  });
  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (!token) {
      navigate('login');
    }
  }, [token]);

  const handleSubmit = () => {
    resetPassword(form.password, token)
      .then((res) => {
        if (res.success) {
          toast.success('password-changed-successfully');
          navigate('/login');
        } else {
          toast.error('error-try-again');
        }
      })
      .catch(() => {
        toast.error('error-try-again');
      });
  };

  return (
    <div className={classes['login']}>
      <div className={classes['login__wrapper']}>
        <div className={classes['login__inside']}>
          <div className={classNames(classes['login__logo'], classes['u-mb-lg'])}></div>
          <h1 className={classNames(classes['login__title'], classes['u-mb-lg'])}>
            Восстановление пароля
          </h1>
          <Input
            placeholder={'Новый пароль'}
            className={classes['email']}
            name={'password'}
            value={form.password}
            onChange={onChange}
            type={'password'}
            icon={LockIcon}
          />
          <Input
            placeholder={'Подтвердите пароль'}
            className={classes['password']}
            name={'confirmPassword'}
            value={form.confirmPassword}
            onChange={onChange}
            type={'password'}
            icon={LockIcon}
          />
          <Button
            name={'Потвердить'}
            variant="auth"
            onClick={handleSubmit}
            className={classes['button']}
            disabled={!form.password.length}
          />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

import React, { useState } from 'react';
import classes from './styles.module.scss';

const Tooltip: React.FC<{
  children: React.ReactNode;
  content: string | undefined;
}> = ({ children, content }) => {
  const [isVisible, setIsVisible] = useState(false);

  const showTooltip = () => setIsVisible(true);
  const hideTooltip = () => setIsVisible(false);

  return (
    <div className={classes['tooltip-container']}>
      {isVisible && content && <div className={classes['tooltip-content']}>{content}</div>}
      <div
        className={classes['tooltip-trigger']}
        onMouseOver={showTooltip}
        onMouseOut={hideTooltip}
        onFocus={showTooltip}
        onBlur={hideTooltip}
        tabIndex={0}
      >
        {children}
      </div>
    </div>
  );
};

export default Tooltip;

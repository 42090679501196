import { useState } from 'react';

export const useEmailValidation = (): [boolean | undefined, (email: string) => void] => {
  const [isValid, setIsValid] = useState<boolean | undefined>(undefined);

  const validateEmail = (email: string): void => {
    if (!email.length) {
      setIsValid(undefined);
      return;
    }
    const re = /\S+@\S+\.\S+/;
    setIsValid(re.test(email));
  };

  return [isValid, validateEmail];
};

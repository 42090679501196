import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import classes from './styles.module.scss';
import { ClosedEyeIcon, LayersIcon, ListIcon, OpenEyeIcon } from 'src/assets/icons';
import { Checkbox } from 'src/components';
import { ICompany, IDivision } from 'src/consts/types';
import classNames from 'classnames';

const layersList = [
  { name: 'АСМ', selected: true, disabled: false },
  { name: 'Отходы и Производства и Потребления', selected: false, disabled: true },
  { name: 'Проверки', selected: false, disabled: true },
  { name: 'Мероприятия в области ООС', selected: false, disabled: true },
  { name: 'Проект в области ООС', selected: false, disabled: true },
  { name: 'Обучение', selected: false, disabled: true },
  { name: 'Законодательные требования', selected: false, disabled: true },
  { name: 'Атмосферный воздух', selected: false, disabled: true },
  { name: 'Водные ресурсы', selected: false, disabled: true },
  { name: 'Парниковые газы', selected: false, disabled: true },
];

type Props = {
  isDivision: boolean;
  companyList: any[];
  onCompanySelected: Dispatch<SetStateAction<ICompany[]>> | Dispatch<SetStateAction<IDivision[]>>;
  handleMinusClick: () => void;
  handlePlusClick: () => void;
};

const Sidebar: React.FC<Props> = ({
  isDivision,
  companyList,
  onCompanySelected,
  handleMinusClick,
  handlePlusClick,
}) => {
  const [selectedDropdown, setSelectedDropdown] = useState<string>('');
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [selectedLayersList, setSelectedLayersList] = useState<string[]>(['АСМ']);

  const [selectedCompanies, setSelectedCompanies] = useState<any[]>([]);

  const handleCheckBoxChanges = (value: ICompany) => {
    setSelectedCompanies((prevList) =>
      prevList.some((company) => company.id === value.id)
        ? prevList.filter((company) => company.id !== value.id)
        : [...prevList, value],
    );
    onCompanySelected((prevList: any) =>
      prevList.some((company: any) => company.id === value.id)
        ? prevList.filter((company: any) => company.id !== value.id)
        : [...prevList, value],
    );
  };

  const handleLayerClick = (value: any) => {
    if (value.disabled) return;
    setSelectedLayersList((prevList) =>
      prevList.includes(value.name)
        ? prevList.filter((layer) => layer !== value.name)
        : [...prevList, value.name],
    );
  };

  const handleNavClick = (type: string) => {
    if (type !== selectedDropdown) {
      setSelectedDropdown(type);
      setIsDropdownOpen(true);
    } else {
      setIsDropdownOpen(false);
      setSelectedDropdown('');
    }
  };

  useEffect(() => {
    setSelectedCompanies(companyList);
  }, [companyList]);

  return (
    <div className={classes['sidebar']}>
      {/* <img src={MinusIcon} alt="minus" onClick={handleMinusClick} />
      <img src={PlusIcon} alt="plus" onClick={handlePlusClick} /> */}
      <img src={LayersIcon} alt="layers" onClick={() => handleNavClick('layers')} />
      {isDropdownOpen && selectedDropdown === 'layers' && (
        <div className={classes['dropdown']} style={{ width: '300px' }}>
          <div className={classes['dropdown__header']}>
            <p>Модули</p>
            <span className={classes['cross']} onClick={() => setIsDropdownOpen(false)}></span>
          </div>
          <ul className={classes['layers']}>
            {layersList.map((layer: any) => (
              <li
                key={layer.name}
                onClick={() => handleLayerClick(layer)}
                className={classNames(
                  classes[`checkbox-wrap--${selectedLayersList.includes(layer.name)}`],
                  classes[`checkbox-wrap--${layer.disabled ? 'disabled' : ''}`],
                )}
              >
                <img
                  alt="eye"
                  src={selectedLayersList.includes(layer.name) ? OpenEyeIcon : ClosedEyeIcon}
                ></img>
                <p>{layer.name}</p>
              </li>
            ))}
          </ul>
        </div>
      )}
      <img src={ListIcon} alt="list" onClick={() => handleNavClick('list')} />
      {isDropdownOpen && selectedDropdown === 'list' && (
        <div className={classNames(classes['dropdown'], classes[`dropdown--last`])}>
          <div className={classes['dropdown__header']}>
            <p>{isDivision ? 'Список дивизионов' : 'Список предприятий'} </p>
            <span className={classes['cross']} onClick={() => setIsDropdownOpen(false)}></span>
          </div>
          <ul className={classNames(classes['modal__checklist'], classes['dropdown__orgs'])}>
            {companyList?.map((item) => (
              <li
                className={
                  classes[
                    `checkbox-wrap--${!!selectedCompanies.find(
                      (company) => company.id === item.id,
                    )}`
                  ]
                }
                key={item}
              >
                <Checkbox
                  label={item.label}
                  checked={!!selectedCompanies.find((company) => company.id === item.id)}
                  onChange={() => handleCheckBoxChanges(item)}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Sidebar;

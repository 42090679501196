import React, { useEffect, useState } from 'react';
import styles from '../styles.module.scss';
import Card from 'src/components/card';
import Select from 'src/components/select';
import { findColorById, getShortName } from 'src/utils/helpers';
import LineChart, { IDataset } from 'src/components/linechart';
import { chartType, getDashboardChart } from 'src/requests/dashboard';
import { format } from 'date-fns';
import { ICompany, IDateRange, IDivision, ISource, SelectOptionType } from 'src/consts/types';
import {
  getCompanyList,
  getCompanySourceList,
  getDivisionsById,
  getDivisionsList,
  getEmissionTypes,
} from 'src/requests/source';
import SelectDates from 'src/pages/main/SelectDates';

const DashboardLineChart: React.FC<{ length?: number }> = ({ length }) => {
  const [selectedRange, setSelectedRange] = useState<IDateRange>({
    dateFrom: new Date(format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")).getTime(),
    dateTo: new Date(format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")).getTime(),
  });
  const [selectedRangeType, setSelectedRangeType] = useState<string>('DAY');
  const [listType, setListType] = useState<string>('company');
  const [selectedCompanies, setSelectedCompanies] = useState<ICompany[]>([]);
  const [chartData, setChartData] = useState<IDataset[]>([]);
  const [xLabels, setXLabels] = useState<string[]>([]);
  const [selectedSources, setSelectedSources] = useState<ISource[]>([]);
  const [defaultCompanyList, setDefaultCompanyList] = useState<ICompany[]>([]);
  const [defaultDivisionList, setDefaultDivisionList] = useState<IDivision[]>([]);
  const [emissionTypes, setEmissionTypes] = useState<SelectOptionType[]>([]);
  const [selectedEmissionType, setSelectedEmissionType] = useState<SelectOptionType[]>([]);
  const [sourcesList, setSourcesList] = useState<ISource[]>([]);

  const handleChangeList = (type: string) => {
    setListType(type);
    setSelectedCompanies([]);
    setSelectedSources([]);
    setSourcesList([]);
    setChartData([]);
  };

  useEffect(() => {
    getEmissionTypes().then((res) => {
      setEmissionTypes(res.list.map((item, index) => ({ label: item, name: item, id: index + 1 })));
      setSelectedEmissionType([{ label: res.list[0], name: res.list[0], id: 1 }]);
    });
  }, []);

  useEffect(() => {
    if (selectedCompanies?.length > 0) {
      if (listType === 'company') {
        getCompanySourceList(selectedCompanies.map((company) => company.id)).then((res) => {
          setSourcesList(
            res.list
              .map((item) => ({ ...item, label: item.name, name: item.name }))
              .sort((a, b) => a.name.localeCompare(b.name)),
          );
        });
      } else {
        getDivisionsById(selectedCompanies.map((company) => company.id)).then((res) => {
          setSourcesList(
            res.list
              .map((item: any) => ({ ...item, label: item.name, name: item.name }))
              .sort((a: any, b: any) => a.name.localeCompare(b.name)),
          );
        });
      }
    }
  }, [selectedCompanies]);

  useEffect(() => {
    getCompanyList().then((res) => {
      const compList = res.list.map((item) => {
        return {
          ...item,
          id: item.id,
          name: getShortName(item.name),
          label: item.name,
          listType: 'company',
          columnName: 'Предприятия',
          backgroundColor: findColorById(item.id),
        };
      });
      setDefaultCompanyList(compList);
    });
    getDivisionsList().then((res) => {
      const divList = res.list.map((item: any) => {
        return {
          ...item,
          id: item.id,
          name: item.name,
          label: item.name,
          listType: 'division',
          columnName: 'Дивизионы',
        };
      });
      setDefaultDivisionList(divList);
    });
  }, []);

  useEffect(() => {
    if (selectedSources.length > 0) {
      const colors = selectedCompanies.map((item) => ({
        title: `${item.name}`,
        color: findColorById(item.id),
      }));

      getDashboardChart(
        selectedRangeType as chartType,
        listType !== 'company',
        selectedEmissionType.map((item) => item.name),
        selectedSources.map((item) => item.id),
      ).then((res) => {
        const resChart: IDataset[] = [];
        let xLabelsArrays: string[] = [];

        res.data.forEach((item: any) => {
          const color = colors.find((c) => c.title === getShortName(item.name))?.color || '#000';

          const params = item.typeChart.param.map((chart: any) => chart && chart.param);

          xLabelsArrays = item.typeChart.param.map((chart: any) => chart && chart.date);

          [params].forEach((data: any) => {
            resChart.push({
              label: getShortName(item.name),
              data: data,
              backgroundColor: color,
              borderColor: color,
              tension: 0.4,
              fill: true,
            });
            if (
              item.typeChart.norma !== undefined ||
              (item.typeChart.norma !== null && typeof item.typeChart.norma === 'number')
            ) {
              resChart.push({
                label: `Норма, ${getShortName(item.name)}`,
                data: Array(data.length).fill(item.typeChart.norma),
                backgroundColor: `${color}80`,
                borderColor: `${color}80`,
                tension: 0.4,
                borderDash: [15, 15],
                fill: false,
              });
            }
          });
        });
        setXLabels(xLabelsArrays);
        setChartData(resChart);
      });
    }
  }, [selectedSources, selectedEmissionType, selectedRangeType]);

  return (
    <div
      className={styles['grid']}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Card
        styles={{
          maxWidth: '1860px',
        }}
      >
        <>
          <div
            style={{
              paddingTop: '30px',
              paddingBottom: '30px',
              paddingLeft: '50px',
              fontSize: '24px',
              fontWeight: 400,
              color: '#383874',
            }}
          >
            Выбросы ЗВ, тонны
          </div>
          <div className={styles['grid2']}>
            <div className={styles['flex']}>
              <div
                style={{
                  display: 'flex',
                  gap: 50,
                }}
              >
                <Select
                  sources
                  label="Предприятия"
                  secondLabel="Дивизион"
                  options={listType === 'company' ? defaultCompanyList : defaultDivisionList}
                  handleSelect={setSelectedCompanies}
                  selectedOptions={selectedCompanies}
                  isMultiple={true}
                  maxLength={
                    (listType === 'company'
                      ? defaultCompanyList.length
                      : defaultDivisionList.length) || 5
                  }
                  listType={listType}
                  handleChangeList={handleChangeList}
                />
                <Select
                  sources
                  label="Источники"
                  options={sourcesList}
                  handleSelect={setSelectedSources}
                  selectedOptions={selectedSources}
                  isMultiple={true}
                  maxLength={5}
                />
                <Select
                  label="Загрязняющее вещество"
                  options={emissionTypes}
                  handleSelect={setSelectedEmissionType}
                  selectedOptions={selectedEmissionType}
                  isMultiple={true}
                  maxLength={5}
                  contentSize={5}
                />
              </div>
              <div className={styles['wrapper__dates']}>
                <SelectDates
                  dates={selectedRange}
                  setDates={setSelectedRange}
                  setSelectedRangeType={setSelectedRangeType}
                  optionsCustom={['День', 'Неделя', 'Месяц', 'Год']}
                  dateRangeHide
                />
              </div>
            </div>
          </div>
          <LineChart
            chartHeight={400}
            length={length}
            datasets={chartData}
            measurementUnit={'тонны'}
            xLabels={xLabels}
          />
        </>
      </Card>
    </div>
  );
};

export default DashboardLineChart;

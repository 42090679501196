import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Routes from './App';
import './assets/sass/main.scss';
import { UserProvider } from './context/useUserContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <BrowserRouter>
    <UserProvider>
      <Routes />
    </UserProvider>
  </BrowserRouter>,
);

import React, { Dispatch, SetStateAction } from 'react';
import styles from './styles.module.scss';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, ChartOptions } from 'chart.js';
import { findColorById } from 'src/utils/helpers';
import DiagramTable from './components/diagram-table';
import Select from '../select';
import {
  DiagramCompanyType,
  DiagramDivisionType,
  DiagramEmissionType,
  ISource,
} from 'src/consts/types';
import { MONTHS_ID, YEARS } from 'src/consts/enum';
import Dropdown from '../dropdown';
Chart.register(ArcElement);

const getOrCreateTooltip = (chart: any) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context: any, data: { name: string; value: number }[]) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  if (tooltip.body) {
    const tableHead = document.createElement('thead');

    data
      .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
      .forEach((item) => {
        const tr = document.createElement('tr');
        (tr as any).style.borderWidth = 0;
        (tr as any).style.color = '#000';

        const th = document.createElement('th');
        (th as any).style.borderWidth = 0;
        (th as any).style.color = '#000';

        const progressBarHTML =
          '<div style="max-width: 70px;overflow: hidden;  color: #38387433; letter-spacing: 5px;">___________________________________________________</div>';

        th.innerHTML = `<div style="min-width: fit-content; display: flex; justify-content: space-between; align-items: center;"><div style="font-weight: 700; font-size: 24px; color: #383874; width: fit-content;">${item.name}</div>${progressBarHTML}<div style="font-weight: 700; font-size: 24px; color: #383874; min-width: fit-content;">${item.value} т</div></div>`;

        tr.appendChild(th);
        tableHead.appendChild(tr);
      });

    const tableRoot = tooltipEl.querySelector('table');

    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    tableRoot.appendChild(tableHead);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
  tooltipEl.style.background = '#fff';
  tooltipEl.style.boxShadow = '0px 31px 25px 0px #2934951F';
  tooltipEl.style.opacity = 1;
  tooltipEl.style.width = 'fit-content';
  tooltipEl.style.height = 'fit-content';
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = '30px';
  tooltipEl.style.zIndex = '1000';
};

const Diagram: React.FC<{
  maxListItem?: number;
  name: string;
  selectedList: { name: string; label: string; id: number }[];
  setSelectedList: Dispatch<SetStateAction<{ name: string; label: string; id: number }[]>>;
  list: DiagramCompanyType[] | DiagramDivisionType[] | DiagramEmissionType[];
  listType: 'company' | 'division' | 'emission';
  year: string;
  setYear: Dispatch<SetStateAction<string>>;
  sourceList?: ISource[];
  selectedSources?: ISource[];
  setSelectedSources?: Dispatch<SetStateAction<ISource[]>>;
  itemNameWidth?: string;
}> = ({
  year,
  name,
  selectedList,
  setSelectedList,
  list,
  listType,
  maxListItem,
  setYear,
  sourceList,
  selectedSources,
  setSelectedSources,
  itemNameWidth,
}) => {
  const data = {
    labels: list.map((item) => item.name),
    datasets: [
      {
        label: '',
        data: list.map((item) => item.params.reduce((acc, param) => acc + param.value, 0)),
        backgroundColor: list.map((item) => findColorById(item.id)),
        borderColor: list.map((item) => findColorById(item.id)),
        borderWidth: 1,
      },
    ],
  };

  const options: ChartOptions<'doughnut'> = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: (context: any) => {
          const foundList = list
            ? (list as DiagramCompanyType[])?.find(
                (item: DiagramCompanyType) =>
                  item?.name === context?.tooltip?.dataPoints?.[0]?.label,
              )
            : null;

          const filteredData = foundList?.params
            ?.map((item: any) => ({
              name: item?.title,
              value: item?.value,
            }))
            .filter((item) => item.name);

          filteredData && externalTooltipHandler(context, filteredData);
        },
      },
    },
  };

  return (
    <div className={styles['card']}>
      <div
        className={styles['diagram__header']}
        style={{
          minHeight: '112px',
        }}
      >
        <div className={styles['diagram__name']}>{name}</div>
        <div
          style={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          <Dropdown
            placeholder={year}
            options={YEARS}
            onSelect={(year) => {
              setYear(year.toString());
            }}
          />
        </div>
        <div
          className={styles['diagram__dropdown']}
          style={{
            position: 'relative',
          }}
        >
          <Select
            options={MONTHS_ID}
            handleSelect={setSelectedList}
            selectedOptions={selectedList}
            maxLength={maxListItem || 5}
            listType={listType}
            minHeight="56px"
            contentSize={1}
            right
          />
          <div
            style={{
              position: 'absolute',
              right: '15px',
            }}
          >
            {listType === 'emission' && (
              <Select
                sources
                options={sourceList}
                handleSelect={setSelectedSources as Dispatch<SetStateAction<ISource[]>>}
                selectedOptions={selectedSources as ISource[]}
                maxLength={sourceList?.length || 5}
                listType={listType}
                placeholder="Все источники"
                isMultiple
                contentSize={1}
                right
              />
            )}
          </div>
        </div>
      </div>

      <div className={styles['doughnut']}>
        <Doughnut data={data} options={options} />
      </div>
      <DiagramTable list={list} itemNameWidth={itemNameWidth} />
    </div>
  );
};

export default Diagram;

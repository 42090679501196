import { api } from './api';
import { editUserDto, IResponse, IUser } from 'src/consts/types';

export const getCurrentUser = (): Promise<IResponse<IUser>> => {
  return api.get('user/current').then((res) => res.data);
};

export const changeUserPassword = (currentPassword: string, newPassword: string) => {
  return api
    .post('user/changePassword', { currentPassword, newPassword })
    .then((res) => res.data)
    .catch((res) => res.data);
};

export const editUser = (data: editUserDto) => {
  return api.put('user/edit', data).then((res) => res.data);
};

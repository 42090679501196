import { format, subDays, startOfDay, subMinutes } from 'date-fns';

const currentDate = new Date();
const timeTwentyMinutesAgo = subMinutes(currentDate, 20);

const dayStartDate = startOfDay(subDays(currentDate, 1));
const dayEndDate = currentDate;

const weekStartDate = startOfDay(subDays(currentDate, 7));
const weekEndDate = currentDate;

const monthStartDate = startOfDay(subDays(currentDate, 31));
const monthEndDate = currentDate;

const yearStartDate = startOfDay(subDays(currentDate, 365));
const yearEndDate = currentDate;

export const formatDate = (date: Date) =>
  new Date(format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")).getTime();

const twentyMinutesRange = {
  startDate: formatDate(timeTwentyMinutesAgo),
  endDate: formatDate(currentDate),
};

const dayRange = {
  startDate: formatDate(dayStartDate),
  endDate: formatDate(dayEndDate),
};
const weekRange = {
  startDate: formatDate(weekStartDate),
  endDate: formatDate(weekEndDate),
};
const monthRange = {
  startDate: formatDate(monthStartDate),
  endDate: formatDate(monthEndDate),
};
const yearRange = {
  startDate: formatDate(yearStartDate),
  endDate: formatDate(yearEndDate),
};

export const getDates = (type: string) => {
  switch (type) {
    case '20 мин.':
      return twentyMinutesRange;
    case 'Неделя':
      return weekRange;
    case 'Месяц':
      return monthRange;
    case 'Год':
      return yearRange;
    case 'День':
    default:
      return dayRange;
  }
};

export const getRange = (type: string) => {
  switch (type) {
    case '20 мин.':
      return 'TWENTY_MINUTE';
    case 'Неделя':
      return 'WEEK';
    case 'Месяц':
      return 'MONTH';
    case 'Год':
      return 'YEAR';
    case 'День':
    default:
      return 'DAY';
  }
};

export const getColor = (value: number): string => {
  if (value > 100) {
    return '#FF3D3D';
  } else if (value >= 90) {
    return '#6496FB';
  } else {
    return '#fff';
  }
};

export const findColorById = (id: number): string => {
  switch (id) {
    case 1:
      return '#FF6A2A';
    case 2:
      return '#E3E3E3';
    case 3:
      return '#FFC32A';
    case 4:
      return '#AE2AFF';
    case 5:
      return '#20DFB1';
    case 6:
      return '#4D6983';
    case 7:
      return '#17D74D';
    case 8:
      return '#669AFF';
    case 9:
    default:
      return '#FF6A2A';
  }
};

export const findByRuCompanyName = (name: string) => {
  switch (name) {
    case 'ССГПО':
      return 'SSGPO';
    case 'АктЗФ':
      return 'AKTZF';
    case 'ДГОК':
      return 'DGOK';
    case 'ПАЗ':
      return 'PAZ';
    case 'ЕЭК РВ':
      return 'EEKRV';
    case 'АксЗФ':
      return 'AKSZF';
    case 'ЕЭК ЭС':
      return 'EEKES';
    case 'КЭЗ':
      return 'KEZ';
  }
};

export const findTopByLatitude = (latitude: string): number => {
  switch (latitude.replace(/\s/g, '')) {
    case '51°10′48.4″': //Астана
      return 29;
    case '43°39′': //Актау
      return 79;
    case '49°48′': //Караганды
      return 43;
    case '53°13′09″': //Костанай
      return 15;
    case '50°25′36.5″': //Семей
      return 37;
    case '42°18′': //Шымкент
      return 80;
    case '50°15′04″': //Хромтау
      return 43;
    case '52°26′39″': //Аксу
      return 25;
    case '50°16′46.9″': //Актобе
      return 40;
    case '52°58′': //Рудный
      return 19;
    case '52°16′59″': //Павлодар
      return 21;
    case '50°82′': //Юго-восток Павлодар
      return 34;
    case '51°43′25″': //Экибастуз
      return 27;
    case '52°35′39″': //рядом Аксу
      return 30;
    default:
      return 30;
  }
};

export const findLeftByLongitude = (longitude: string): number => {
  switch (longitude.replace(/\s/g, '')) {
    case '71°26′45.6″': //Астана
      return 61;
    case '51°09′': //Актау
      return 11;
    case '73°07′': //Караганды
      return 63;
    case '80°16′0.1″': //Костанай
      return 80;
    case '63°38′03″': //Семей
      return 39.5;
    case '69°36′': //Шымкент
      return 54;
    case '58°26′24″': //Хромтау
      return 32;
    case '71°57′27″': //Аксу
      return 73;
    case '57°12′25.9″': //Актобе
      return 26;
    case '63°07′': //Рудный
      return 38;
    case '76°58′00″': //Павлодар
      return 70;
    case '77°82′': //Юго-восток Павлодар
      return 76;
    case '75°19′22″': //Экибастуз
      return 67;
    case '71°60′27″': //рядом Аксу
      return 74;
    default:
      return 50;
  }
};

export const getShortName = (name: string) => {
  const match = name.match(/\(([^)]+)\)/);
  if (match) {
    return match[1];
  } else {
    return name;
  }
};

import { ACCESS_TOKEN, REFRESH_TOKEN, ROLES } from '../consts/enum';

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN) || '';
};

export const getRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN) || '';
};

export const setTokens = (accessToken: string, refreshToken: string) => {
  accessToken && localStorage.setItem(ACCESS_TOKEN, accessToken);
  refreshToken && localStorage.setItem(REFRESH_TOKEN, refreshToken);
};

export const removeTokens = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
};

export const setRoles = (roles: string[]) => {
  localStorage.setItem(ROLES, JSON.stringify(roles));
};

export const removeRoles = () => {
  localStorage.removeItem(ROLES);
};

export const getRoles = () => {
  return localStorage.getItem(ROLES) || '';
};

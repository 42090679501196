import React, { useState } from 'react';
import classes from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from 'src/context/useUserContext';
import { ArrowDown, HomeSmallIcon } from 'src/assets/icons';
import { removeTokens } from 'src/utils/token';
import classNames from 'classnames';
import emissionLogo from 'src/assets/images/emissionLogo.png';

interface IModule {
  id: number;
  title: string;
  link: string;
  disabled: boolean;
  subModules?: IModule[];
}

const moduleList: IModule[] = [
  {
    id: 1,
    title: 'Водные ресурсы',
    link: '',
    disabled: true,
  },
  {
    id: 2,
    title: 'Атмосферный воздух',
    link: '',
    disabled: true,
  },
  {
    id: 3,
    title: 'АСМ',
    link: '',
    disabled: false,
    subModules: [
      { id: 4, title: 'Выбросы', link: '/main', disabled: false },
      { id: 5, title: 'Сбросы', link: '', disabled: true },
    ],
  },
  {
    id: 6,
    title: 'ОПП',
    link: '',
    disabled: true,
  },
  {
    id: 7,
    title: 'Разрешение на эмиссии',
    link: '',
    disabled: true,
  },
  {
    id: 8,
    title: 'Парниковые газы',
    link: '',
    disabled: true,
  },
  {
    id: 9,
    title: 'Управление проверками',
    link: '',
    disabled: true,
  },
  {
    id: 10,
    title: 'Управление мероприятиями в области ООС',
    link: '',
    disabled: true,
  },
  {
    id: 11,
    title: 'Управление проектами',
    link: '',
    disabled: true,
  },
];

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [showSubModules, setShowSubModules] = useState<number>(0);

  const handleLogOut = () => {
    removeTokens();
    navigate('/login');
  };

  const handleModuleClick = (module: IModule) => {
    if (module.id === showSubModules) {
      setShowSubModules(0);
    } else if (module.subModules) {
      setShowSubModules(module.id);
    } else {
      navigate(module.link);
    }
  };

  if (!user) return null;
  // TODO: shadow select
  return (
    <div className={classes['home']}>
      <div className={classes['home__header']}>
        <div className={classes['home__header__icons']}>
          <div className={classes['profile']} onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <span>{user.firstName[0] + user.lastName[0]}</span>
            <img src={ArrowDown} alt="arr" />
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <div className={classes['menu']}>
          <p>{user.firstName + ' ' + user.lastName}</p>
          <span>{user.position}</span>
          <ul>
            <li onClick={handleLogOut}>
              <img src={HomeSmallIcon} alt="home" />
              <p>Выход</p>
            </li>
          </ul>
        </div>
      )}
      <div className={classes['home__body']}>
        <h1>Добро пожаловать, {user.firstName + ' ' + user.lastName}</h1>
        <div className={classes['dropdown-wrap']}>
          <div
            style={{
              padding: '15px 50px',
            }}
            className={classes['dropdown']}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <div className={classes['dropdown-current']}>Выберите Модуль</div>
            <img src={ArrowDown} alt="" />
          </div>
          {isDropdownOpen && (
            <div className={classes['dropdown__options']}>
              {moduleList.map((item: IModule) => (
                <div key={item.id}>
                  <div
                    className={classNames(
                      classes['dropdown__option-wrap'],
                      classes[`dropdown__option-wrap--${item.disabled && 'disabled'}`],
                    )}
                    onClick={() => handleModuleClick(item)}
                  >
                    <p>{item.title}</p>
                    {item.subModules && <img src={ArrowDown} alt="" />}
                  </div>
                  {showSubModules === item.id &&
                    item.subModules?.map((item) => (
                      <div
                        key={item.id}
                        className={classNames(
                          classes['dropdown__option-wrap2'],
                          classes[`dropdown__option-wrap2--${item.disabled && 'disabled'}`],
                        )}
                        onClick={() => handleModuleClick(item)}
                      >
                        <p>{item.title}</p>
                      </div>
                    ))}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className={classes['emissionLogo']}>
        <img src={emissionLogo} alt="Логотип" />
      </div>
    </div>
  );
};

export default HomePage;

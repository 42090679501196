import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import classes from './styles.module.scss';
import { MapImg } from 'src/assets/images';
import { ORGicon } from 'src/assets/icons';
import { ICompany, ISource } from 'src/consts/types';
import {
  findTopByLatitude,
  findLeftByLongitude,
  findColorById,
  findByRuCompanyName,
} from 'src/utils/helpers';
import { getCompanySourceList, getDivisionsById } from 'src/requests/source';
import classNames from 'classnames';

interface IMapPoint extends ICompany {
  top: number;
  left: number;
}

interface Props {
  points: ICompany[];
  setSourcesList: Dispatch<SetStateAction<ISource[]>>;
  setSelectedCompany: Dispatch<SetStateAction<ICompany | undefined>>;
  setSelectedSource: Dispatch<SetStateAction<ISource | undefined>>;
  isDivision?: boolean;
}

const Map: React.FC<Props> = ({
  points,
  setSourcesList,
  setSelectedCompany,
  setSelectedSource,
  isDivision,
}) => {
  const [mapPoints, setMapPoints] = useState<IMapPoint[]>([]);

  const handleIconClick = (item: any, companyName?: any) => {
    const companyEnName = findByRuCompanyName(companyName);
    setSelectedCompany(item);
    (isDivision ? getDivisionsById : getCompanySourceList)([item.id]).then((res) => {
      const filteredList = res.list.filter((item: any) => item.name.includes(companyEnName));
      setSourcesList(isDivision ? filteredList : res.list);
      setSelectedSource(isDivision ? filteredList[0] : res.list[0]);
    });
  };

  useEffect(() => {
    const found = points.map((item) => ({
      ...item,
      top: findTopByLatitude(item.latitude),
      left: findLeftByLongitude(item.longitude),
    }));
    setMapPoints(found);
  }, [points]);

  return (
    <div className={classes['map']}>
      <img src={MapImg} alt="map" />
      {mapPoints.map((item) => (
        <span key={item.name}>
          <span
            style={{ top: `${item.top - 15}%`, left: `${item.left - 7}%` }}
            className={classNames(classes[`circle`], classes[`circle--${item.level}`])}
          />
          <div
            className={classes['org-point']}
            style={{ top: `${item.top - 5}%`, left: `${item.left - 3}%` }}
            onClick={() => !isDivision && handleIconClick(item)}
          >
            {isDivision ? (
              item.divisions.map((division) => (
                <div
                  className={classes['org-point__division']}
                  key={division.id}
                  onClick={() => isDivision && handleIconClick(division, item.name)}
                >
                  <img
                    src={ORGicon}
                    alt="icon"
                    style={{ backgroundColor: findColorById(division.id) }}
                  />
                  <p>{division.name}</p>
                </div>
              ))
            ) : (
              <>
                <img src={ORGicon} alt="icon" style={{ backgroundColor: item.backgroundColor }} />
                <p>{item.name}</p>
              </>
            )}
          </div>
        </span>
      ))}
    </div>
  );
};

export default Map;

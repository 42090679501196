import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { findColorById } from 'src/utils/helpers';
import { DiagramCompanyType, DiagramDivisionType, DiagramEmissionType } from 'src/consts/types';

const DiagramTable: React.FC<{
  list: DiagramCompanyType[] | DiagramDivisionType[] | DiagramEmissionType[];
  itemNameWidth?: string;
}> = ({ list, itemNameWidth }) => {
  const [maxValueString, setMaxValueString] = useState<string>('');

  const findMaxValueString = () => {
    let maxValue = '';

    list.map((item) => {
      let fullValue = 0;
      let fullNorma = 0;
      item.params.map((param) => {
        fullValue += param.value;
        fullNorma += param.norma;
      });

      const value = fullValue.toFixed(2).toString() + ' ' + fullNorma.toFixed(2).toString();

      if (maxValue.length < value.length) {
        maxValue = value;
      }
    });
    return maxValue.split(' ')[1] + 'т/г' + ' ' + maxValue.split(' ')[0] + 'т';
  };

  useEffect(() => {
    const maxValue = findMaxValueString();
    setMaxValueString(maxValue);
  }, [list]);

  return (
    <div className={styles['table']}>
      <div className={styles['column']}>
        <div
          style={{
            display: 'flex',
            gap: 22,
            fontSize: '24px',
            position: 'relative',
          }}
        >
          <div
            style={{
              display: 'flex',
              gap: 22,
            }}
          >
            {list.length !== 0 && (
              <div
                style={{
                  display: 'flex',
                  opacity: 1,
                  gap: 22,
                  overflow: 'hidden',
                  maxWidth: '230px',
                  whiteSpace: 'nowrap',
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    position: 'relative',
                  }}
                >
                  <div
                    style={{
                      opacity: 0,
                    }}
                  >
                    {maxValueString.split(' ')[0]}
                  </div>
                  <div className={styles['column__name']}>Норма</div>
                </div>
                <div
                  style={{
                    position: 'relative',
                  }}
                >
                  <div
                    style={{
                      opacity: 0,
                    }}
                  >
                    {maxValueString.split(' ')[1]}
                  </div>
                  <div className={styles['column__name']}>Факт</div>
                </div>
              </div>
            )}

            <div
              style={{
                opacity: 0,
              }}
              className={styles[`triangle-up`]}
            ></div>
          </div>
        </div>
      </div>
      {list.map((item, index) => (
        <div key={item.id} className={styles['table__item']}>
          <div className={styles['item__name']}>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
              >
                <circle
                  cx="5"
                  cy="5"
                  r="5"
                  fill={findColorById(item.listType === 'emission' ? index + 1 : item.id)}
                />
              </svg>
            </span>
            <span style={{ width: itemNameWidth }}>{item.name}</span>
          </div>
          <div className={styles['item__divider']}>
            __________________________________________________________________
          </div>
          <div className={styles['item__values']}>
            <div
              className={styles['values__wrapper']}
              style={{
                display: 'flex',
                whiteSpace: 'nowrap',
                overflowX:
                  item.params.reduce((acc, param) => acc + param.norma, 0).toFixed(2).length +
                    item.params.reduce((acc, param) => acc + param.value, 0).toFixed(2).length >=
                  14
                    ? 'auto'
                    : 'hidden',
              }}
            >
              <div style={{ position: 'relative' }}>
                <span style={{ opacity: 0 }}>{maxValueString.split(' ')[0]}</span>
                <span
                  style={{
                    position: 'absolute',
                    textAlign: 'center',
                    top: 0,
                    left: 0,
                    width: '100%',
                    textAlignLast: 'center',
                  }}
                >
                  {item.params.reduce((acc, param) => acc + param.norma, 0).toFixed(2)}т/г
                </span>
              </div>
              <div style={{ position: 'relative' }}>
                <span style={{ opacity: 0 }}>{maxValueString.split(' ')[1]}</span>
                <span
                  style={{
                    position: 'absolute',
                    textAlign: 'center',
                    top: 0,
                    left: 0,
                    width: '100%',
                    textAlignLast: 'center',
                    color: '#38387480',
                  }}
                >
                  {item.params.reduce((acc, param) => acc + param.value, 0).toFixed(2)}т
                </span>
              </div>
            </div>
            <span
              className={
                styles[
                  `triangle-${
                    item.params.reduce((acc, param) => acc + param.value, 0) >
                    item.params.reduce((acc, param) => acc + param.norma, 0)
                      ? 'up'
                      : 'down'
                  }`
                ]
              }
            ></span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DiagramTable;

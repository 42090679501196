import React, { useEffect, useState } from 'react';
import classes from './styles.module.scss';
import Card from 'src/components/card';
import { getTable2Data } from 'src/requests/source';
import { format } from 'date-fns';
import { ISource } from 'src/consts/types';

type PropsType = {
  selectedSources: ISource[];
};

const Table2: React.FC<PropsType> = ({ selectedSources }) => {
  const [tableData, setTableData] = useState<any[]>([]);

  useEffect(() => {
    selectedSources.length &&
      getTable2Data(selectedSources.map((item) => item.id)).then((res) => setTableData(res.list));
  }, [selectedSources]);

  const groupedData: { [key: string]: any[] } = {};

  tableData.forEach((item) => {
    if (!groupedData[item.sourceName]) {
      groupedData[item.sourceName] = [];
    }
    groupedData[item.sourceName].push(item);
  });

  return (
    <Card
      styles={{
        maxWidth: '100%',
        display: 'block',
        justifyContent: 'center',
        maxHeight: '500px',
        overflow: 'auto',
      }}
    >
      <div className={classes['wrapper']}>
        <div
          className={classes['table__name']}
          style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}
        >
          Максимальные значения
        </div>
        <div style={{ width: '100%', overflowX: 'auto' }}>
          <table className={classes['table']}>
            <thead>
              <tr>
                <th rowSpan={2}>Источники </th>
                <th rowSpan={2}>Загрязняющее вещество </th>
                <th colSpan={2}>
                  Макс. значения выбросов ЗВ, <br /> г/с
                </th>
                <th colSpan={2}>
                  Макс. значения концентрации ЗВ,
                  <br /> мг/нм3
                </th>
              </tr>
              <tr>
                <th>Дата и Время</th>
                <th>Значение</th>
                <th>Дата и Время</th>
                <th>Значение</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(groupedData)
                .sort()
                .map((sourceName) =>
                  groupedData[sourceName].map((item, index) => (
                    <tr key={index}>
                      {index === 0 && (
                        <td
                          style={{
                            fontSize: '32px',
                            fontWeight: 700,
                          }}
                          rowSpan={groupedData[sourceName].length}
                        >
                          {sourceName}
                        </td>
                      )}
                      <td
                        style={{
                          fontSize: '32px',
                          fontWeight: 700,
                        }}
                      >
                        {item.type}
                      </td>
                      <td>
                        {item.params[0] && (
                          <>
                            {format(new Date(item.params[0]?.date), 'dd.MM.yyyy')}
                            <br />
                            {format(new Date(item.params[0]?.date), 'HH:mm')}
                          </>
                        )}
                      </td>
                      <td>{item.params[0]?.value}</td>
                      <td>
                        {item.params[1] && (
                          <>
                            {format(new Date(item.params[1]?.date), 'dd.MM.yyyy')}
                            <br />
                            {format(new Date(item.params[1]?.date), 'HH:mm')}
                          </>
                        )}
                      </td>
                      <td>{item.params[1]?.value}</td>
                    </tr>
                  )),
                )}
            </tbody>
          </table>
        </div>
      </div>
    </Card>
  );
};

export default Table2;

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useEmailValidation } from 'src/hooks';
import { Button, Input } from 'src/components';
import { forgotPassword } from 'src/requests/auth';

import classes from './styles.module.scss';
import classNames from 'classnames';
import { MailIcon } from 'src/assets/icons';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [isValid, validateEmail] = useEmailValidation();
  const [step, setStep] = useState<number>(0);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    validateEmail(e.target.value);
    setEmail(e.target.value);
  };

  const handleButtonClick = () => {
    setStep(1);
    // forgotPassword(email)
    //   .then((res) => {
    //     if (res.success) {
    //       toast.success('email-with-instruction-sent');
    //       setStep(1);
    //     } else {
    //       toast.error('error-try-again');
    //     }
    //   })
    //   .catch(() => {
    //     toast.error('error-try-again');
    //   });
  };

  return (
    <div className={classes['login']}>
      <div className={classes['login__wrapper']}>
        <h1 className={classNames(classes['login__title'])}>Восстановление пароля</h1>
        <div className={classes['login__inside']}>
          {step === 0 ? (
            <>
              <div className={classes['text-wrap']}>
                <p>
                  Введите адрес электронной почты, который вы использовали при регистрации, и мы
                  отправим вам инструкцию
                </p>
              </div>
              <Input
                isValid={isValid}
                placeholder="email"
                className={classes['email']}
                name={'email'}
                value={email}
                onChange={handleEmailChange}
                errorMsg="Неправильный пароль"
                icon={MailIcon}
              />
              <Button
                name="Отправить"
                variant="auth"
                onClick={handleButtonClick}
                className={classes['button']}
                // disabled={!isValid}
              />
              <Link to={'/login'} className={classes['login__link']}>
                ← Назад
              </Link>
            </>
          ) : (
            <>
              <div className={classes['text-wrap']}>
                <p>
                  {`На вашу почту ${email} отправлено письмо со ссылкой на восстановление
                  пароля`}
                </p>
              </div>
              <Button
                name="Назад"
                variant="auth"
                onClick={() => navigate('/login')}
                className={classes['button']}
              />
              <p className={classes['forgot__text']}>Не пришло письмо?</p>
              <p className={classes['forgot__text--uppercase']} onClick={handleButtonClick}>
                Отправить письмо повторно
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

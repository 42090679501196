import React from 'react';
import classes from './styles.module.scss';
import classNames from 'classnames';

interface ICheckboxProps {
  label: string;
  checked: boolean;
  onChange: (e: any) => void;
  disabled?: boolean;
  colorChange?: boolean;
}

const Checkbox: React.FC<ICheckboxProps> = ({
  label,
  checked,
  onChange,
  disabled = false,
  colorChange = true,
}) => {
  // TODO: Implement the checkbox component

  return (
    <div
      className={classNames(
        classes['checkbox-wrapper'],
        classes[`checkbox-wrapper--${disabled ? 'disabled' : ''}`],
      )}
      onClick={onChange}
    >
      <input
        type="checkbox"
        id="myCheckbox"
        checked={checked}
        disabled={disabled}
        onChange={() => console.log()}
      />
      <div className={classes['checkbox-background']}></div>
      <label
        className={classNames(
          classes[`checkbox-label--${checked ? 'checked' : 'not'}`],
          classes[`checkbox-label--${disabled ? 'disabled' : ''}`],
        )}
      >
        {label}
      </label>
    </div>
  );
};

export default Checkbox;

import React, { useState, useRef, useEffect } from 'react';
import classes from './styles.module.scss';

type Option = {
  label: string;
  value: string | number;
};

interface DropdownProps {
  options: Option[];
  onSelect: (option: string | number) => void;
  placeholder: JSX.Element | string;
}

const Dropdown: React.FC<DropdownProps> = ({ options, onSelect, placeholder }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selection, setSelection] = useState<string | number | undefined>();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (option: Option) => {
    setSelection(option.label);
    onSelect(option.value);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className={classes['dropdown__wrapper']} ref={dropdownRef}>
      <div className={classes['dropdown__selection']} onClick={toggleDropdown}>
        {selection || placeholder}
      </div>
      {isOpen && (
        <ul className={classes['dropdown__list-wrapper']}>
          {options.map((option, index) => (
            <li
              className={`${classes['dropdown__list-item']} ${
                selection === option.label ? classes['disabled'] : ''
              }`}
              key={index}
              onClick={() => selection !== option.label && handleSelect(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
